exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-detail-index-js": () => import("./../../../src/pages/detail/index.js" /* webpackChunkName: "component---src-pages-detail-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-popular-index-js": () => import("./../../../src/pages/popular/index.js" /* webpackChunkName: "component---src-pages-popular-index-js" */),
  "component---src-pages-recommendations-index-js": () => import("./../../../src/pages/recommendations/index.js" /* webpackChunkName: "component---src-pages-recommendations-index-js" */),
  "component---src-pages-search-index-js": () => import("./../../../src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-pages-server-index-js": () => import("./../../../src/pages/server/index.js" /* webpackChunkName: "component---src-pages-server-index-js" */),
  "component---src-pages-trending-index-js": () => import("./../../../src/pages/trending/index.js" /* webpackChunkName: "component---src-pages-trending-index-js" */),
  "component---src-pages-watch-index-js": () => import("./../../../src/pages/watch/index.js" /* webpackChunkName: "component---src-pages-watch-index-js" */)
}

